<template>
    <div class="w-100">
        <div class="row">
            <div v-if="itemCurrent.url" class="ml-4 position-relative">
                <img
                    loading="lazy"
                    :src="
                        itemCurrent.uploaded
                            ? itemCurrent.url.replace('large', 'normal')
                            : itemCurrent.url
                    "
                    :alt="itemCurrent.alt"
                    width="120"
                    class="rounded border border-secondary"
                />
                <div class="position-absolute -top-1 right-0 d-flex">
                    <button
                        v-if="enabled"
                        class="btn btn-info len-3 d-flex align-items-center justify-content-center"
                        @click="openNewImage"
                    >
                        🖊
                    </button>
                </div>
            </div>
            <div v-else>
                <vs-tooltip v-if="enabled" border>
                    <button
                        class="ml-4 border border-secondary btn rounded len-120px d-flex justify-content-center align-items-center"
                        @click="openNewImage"
                    >
                        <span class="h2">➕</span>
                    </button>
                    <template #tooltip> Agregar imagen</template>
                </vs-tooltip>
                <div v-else>
                    <span class="h2"> Sin imagen </span>
                </div>
            </div>
        </div>
        <div>
            <image-selector
                v-model="isOpenSelectorImage"
                :enabled-create="enabledCreate"
                :default-filters="defaultFilters"
                :message="message"
                @input="onSelectImage"
            />
        </div>
    </div>
</template>

<script>
import { DEFAULT_IMAGE_URL } from "@/common/constants";

import ImageSelector from "@/components/utils/ImageSelector.vue";

export default {
    name: "ImageManage",
    components: { ImageSelector },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: Object,
            default: () => ({
                alt: "alt",
                id: 1,
                url: "",
                image: {
                    id: 1,
                    url: DEFAULT_IMAGE_URL,
                    alt: "Chaqueta de sudadera colegio montessori 2 kennedy Bogotá."
                }
            }),
            required: true
        },
        enabledCreate: {
            default: () => false,
            type: Boolean,
            required: false
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        defaultFilters: {
            default: () => ({}),
            type: Object,
            required: false
        },
        message: { default: () => "", type: String, required: false }
    },
    emits: ["change"],
    data: () => ({
        isOpenSelectorImage: false
    }),
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        async openNewImage() {
            this.isOpenSelectorImage = true;
        },
        onSelectImage(image) {
            this.$emit("change", image);
        }
    }
};
</script>
